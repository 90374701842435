const LOGOS = [
  "/logo-emirates.svg",
  "/logo-hdfc.svg",
  "/logo-mariott.svg",
  "/logo-delta.svg",
  "/logo-hilton.svg",
  "/logo-axis.svg",
  "/logo-accor.svg",
  "/logo-singapore-airlines.svg",
  "/logo-emirates.svg",
  "/logo-hdfc.svg",
  "/logo-mariott.svg",
  "/logo-delta.svg",
  "/logo-hilton.svg",
  "/logo-axis.svg",
  "/logo-accor.svg",
  "/logo-singapore-airlines.svg",
  "/logo-emirates.svg",
  "/logo-hdfc.svg",
  "/logo-mariott.svg",
  "/logo-delta.svg",
  "/logo-hilton.svg",
  "/logo-axis.svg",
  "/logo-accor.svg",
  "/logo-singapore-airlines.svg",
  "/logo-emirates.svg",
  "/logo-hdfc.svg",
  "/logo-mariott.svg",
  "/logo-delta.svg",
  "/logo-hilton.svg",
  "/logo-axis.svg",
  "/logo-accor.svg",
  "/logo-singapore-airlines.svg",
];

const LogoCarousel = () => {
  return (
    <div className="w-full overflow-hidden pc:pb-[5rem] md:pb-[6rem] pb-[5rem] bg-[#FAF9F6]">
      <p className="m-0 mx-[1rem] pc:mt-[5rem] md:mt-[6rem] mt-[5rem] pc:mb-[1.125rem] md:mb-[1rem] mb-[1.125rem] font-body-lg-medium font-medium md:text-[1.125rem] text-[1rem] leading-[1.875rem] text-[#52525B] text-center">
        Track loyalty programs across hotels, airlines and cards
      </p>
      <h1 className="m-0 mx-[1rem] pc:mb-[5rem] mb-[3.5rem] font-body-lg-medium font-extrabold pc:text-[2.625] md:text-[2.125rem] text-[1.75rem] text-[#111827] md:leading-[3.625rem] leading-normal text-center">
        200+ Loyalty Programs on Magnify
      </h1>
      <div className="flex animate-infinite-slider">
        {LOGOS.map((logo, index) => (
          <div
            className="slide flex w-[20rem] items-center justify-center"
            key={index}
          >
            <img
              key={index}
              src={logo}
              alt={`Carousel image ${index + 1}`}
              className="w-[7rem] flex-shrink-0 mr-[6rem]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogoCarousel;
