import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Manifest from "../constants/Manifest";

const Header: FunctionComponent = () => {
  const [showList, setShowList] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLImageElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        toggleButtonRef.current &&
        !toggleButtonRef.current.contains(event.target as Node)
      ) {
        setShowList(false);
      }
    };

    const handleScroll = () => {
      setShowList(false);
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="items-center z-20 justify-start w-full pc:text-[1.25rem] text-[1.125rem] font-semibold font-body-lg-medium leading-[4.5rem] text-[#111827]">
      <div className="hidden md:flex">
        <img
          onClick={openHome}
          className="w-[8rem] mr-[3rem] pc:ml-[6.563rem] ml-[2.53rem] cursor-pointer"
          src="/magnify-tm.svg"
          alt=""
        />
        <span onClick={openBlogs} className="mr-[3rem] cursor-pointer">
          Blogs
        </span>
        <span
          onClick={openClubVistaraSweetSpotsPage}
          className="mr-[3rem] cursor-pointer"
        >
          Vistara Gems
        </span>
        <span className="cursor-pointer">FAQs</span>
      </div>
      <div className="mx-[1rem] md:hidden flex flex-col">
        <div className="flex items-center justify-between w-full py-[1rem] ">
          <img
            onClick={openHome}
            className="w-[8rem] cursor-pointer"
            src="/magnify-tm.svg"
            alt=""
          />
          <img
            ref={toggleButtonRef}
            src={showList ? "/cross2.svg" : "/navbars.svg"}
            onClick={() => setShowList((prev) => !prev)}
            className="w-[1.172rem]"
          />
        </div>
        {showList && (
          <div ref={dropdownRef} className=" text-[#111827]">
            <div
              onClick={openHome}
              className="leading-normal text-[1.125rem] font-semibold py-[1rem] cursor-pointer font-body-lg-medium"
            >
              Home
            </div>
            <div
              onClick={openBlogs}
              className="leading-normal text-[1.125rem] font-semibold py-[1rem] cursor-pointer font-body-lg-medium"
            >
              Blogs
            </div>
            <div
              onClick={openClubVistaraSweetSpotsPage}
              className="leading-normal text-[1.125rem] font-semibold py-[1rem] cursor-pointer font-body-lg-medium"
            >
              Vistara Gems
            </div>
          </div>
        )}
      </div>
    </header>
  );

  function openHome() {
    window.open("/", "_self");
  }
  function openBlogs() {
    window.open(Manifest.blogUrl, "_self");
  }
  function openClubVistaraSweetSpotsPage() {
    navigate("/club-vistara-sweet-spots-before-merger");
  }
};

export default Header;
