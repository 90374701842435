import React, { Component, ReactNode } from "react";
interface Props {
  children?: ReactNode;
  FallbackUI?: React.ComponentType<any>;
}

interface State {
  hasError: boolean;
}
class ErrorBoundaryComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error("Error caught in ErrorBoundary:", error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { FallbackUI, children } = this.props;

    if (hasError) {
      return FallbackUI ? <FallbackUI /> : <div>Something went wrong.</div>;
    }

    return children;
  }
}

export default function ErrorBoundary(FallbackUI?: any) {
  return (WrappedComponent: any) => {
    return (props: any) => (
      <ErrorBoundaryComponent FallbackUI={FallbackUI}>
        <WrappedComponent {...props} />
      </ErrorBoundaryComponent>
    );
  };
}
