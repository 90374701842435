export default {
  GOOGLE_PLAY_STORE_LINK:
    "https://play.google.com/store/apps/details?id=com.wicked.magnify",
  IOS_APP_STORE_LINK:
    "https://apps.apple.com/us/app/magnify-your-rewards-companion/id6449392706",
  SERVER_URL: "https://api.magnify.club/api",
  GOOGLE_ANALYTICS_TAG_ID: "G-Z9X0HHZ5X7",
  SITE_URL: "https://magnify.club/",
  blogUrl: "https://blog.magnify.club",
};
