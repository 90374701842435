import React from "react";

interface Props {
  isSelected: boolean;
  onClick: () => void;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

function Button({ isSelected, onClick, children, className, style }: Props) {
  return (
    <button
      onClick={onClick}
      className={`mr-[1rem] p-[0.625rem] text-[0.875rem] font-body-lg-medium font-medium border-none rounded-3xl ${
        isSelected ? "bg-[#151517] text-white" : "bg-white text-[#0C0E10]"
      } ${className}`}
      style={style}
    >
      {children}
    </button>
  );
}

export default Button;
