import React, { useEffect, useRef, useState } from "react";
import FlipNumbers from "react-flip-numbers";

type Props = {
  containerClasses: string;
  number: number;
  size: number;
};

const NumberRoller: React.FC<Props> = ({ containerClasses, number, size }) => {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const width =
    size === 64
      ? size - 20
      : size === 48
      ? size - 16
      : size === 28
      ? size - 8
      : size === 34
      ? size - 11
      : size === 40
      ? size - 10
      : size;

  return (
    <div className={containerClasses} ref={containerRef}>
      <FlipNumbers
        height={size}
        width={width}
        numberStyle={{
          fontSize: size,
        }}
        nonNumberStyle={{
          fontSize: size,
        }}
        color="#111827"
        background="white"
        duration={3}
        play={isVisible}
        perspective={20000}
        numbers={number.toLocaleString()}
      />
    </div>
  );
};

export default NumberRoller;
