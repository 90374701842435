import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { airports } from "../clubVistaraSweetSpots/airports";
import Header from "../../components/header";
import "./clubVistaraSweetSpotsResult.css";
import Manifest from "../../constants/Manifest";
import { Tooltip } from "react-tooltip";
import { UaEventOptions } from "react-ga4/types/ga4";
import ReactGA from "react-ga4";

export default function ClubVistaraSweetSpotsResult({}: {}) {
  const location = useLocation();
  const { data } = location.state || { data: [] };
  const [resultData, setResultData] = useState(data);
  const [city, setCity] = useState("");
  const [finalCity, setFinalCity] = useState("");

  const inputRef = useRef<HTMLInputElement>(null);
  const vistaraBookingUrl =
    "https://www.airvistara.com/in/en/plantravel/flight-booking?utm_source=magnify-club&utm_medium=sweet-spots-air-india";
  const airIndiaBookingUrl = "https://clnk.in/u303";
  const buyMeCoffeeURL = "https://buymeacoffee.com/magnify";

  const filteredAirports =
    city === ""
      ? []
      : airports.filter(
          (airports: any) =>
            airports.city.toLowerCase().startsWith(city.toLowerCase()) ||
            airports.code.toLowerCase().startsWith(city.toLowerCase())
        );

  function handleChange(event: any) {
    setCity(event?.target.value);
  }

  function handleClick(city: string) {
    const event: UaEventOptions & { [key: string]: any } = {
      category: "air india vistara merger",
      action: "click",
      label: "source city",
      value: 1,
      source_city: city,
    };
    if (process.env.NODE_ENV !== "development") {
      ReactGA.event(event);
    }
    setFinalCity(city);
  }

  const handleBlur = () => {
    setCity("");
    if (inputRef.current) {
      inputRef.current.blur();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (finalCity) {
          const response = await fetch(
            `${Manifest.SERVER_URL}/reward/vistara-airindia-routes-comparison?city=${finalCity}`
          );
          const data = await response.json();
          setResultData(data.data);
          setCity("");
        }
      } catch (error) {}
    };
    fetchData();
  }, [finalCity]);

  return (
    <div className="items-center w-[100%] flex flex-col relative overflow-hidden">
      <Header />
      <a
        href={buyMeCoffeeURL}
        target="_blank"
        rel="noopener noreferrer"
        className="md:px-[1rem] z-30 px-0 md:py-[0.75rem] py-0 no-underline text-[1.25rem] bg-white text-black font-dm md:border-2 border-0 border-[#FFDD00] border-solid font-bold rounded-full absolute md:right-[1rem] 3xs:right-[3rem] right-[3rem] md:top-[1rem] top-[1.2rem] hover:bg-slate-100 flex items-center h-auto"
      >
        <img
          src="/coffee.gif"
          className="md:w-[2rem] md:h-[2rem] w-[1.7rem] h-[1.7rem] md:mr-2 mr-0"
        />
        <div className=" md:flex" hidden>
          Support Us
        </div>
      </a>

      <div className="md:mt-[6rem] mt-[2.5rem] md:px-[0] px-2 mb-[2rem]">
        <div className="relative flex flex-col items-center">
          <div className="md:text-[2.5rem] lg:text-[3rem] text-[2rem] font-dm font-bold text-[#1C1939] text-center md:mb-[2rem] mb-[1.5rem]">
            Air Vistara & Air India Sweet Spots Finder
          </div>
        </div>
        <div className="relative lg:w-[auto] w-auto h-auto">
          <div className="relative flex  flex-col lg:mb-[6rem] md:mb-[4.5rem] mb-[4rem] w-[100%]">
            <input
              type="text"
              placeholder="Enter Source Airport"
              value={city}
              onChange={handleChange}
              ref={inputRef}
              className=" lg:w-[54rem] md:w-[calc(100% - 3rem)]  w-[calc(100% - 2rem)] lg:pl-[4rem] md:pl-[3rem] pl-[2rem] lg:h-[4.5rem] md:h-[3rem] h-[2rem] border-2 border-[#E0DCFF] focus:outline-none border-solid rounded-full  placeholder-text lg:text-[1.5rem] md:text-[1.2rem] text-[0.9rem] text-[#9E9E9E] custom-shadow"
            />
            <div className="font-dm font-semibold md:text-[1.5rem] text-[1.2rem] absolute lg:top-[7.3rem] md:top-[5rem] md:self-start self-center top-[4rem] text-[#1C1939]">{`Flight from ${resultData[0]["from"]} to:`}</div>
            <img
              className="lg:w-[1.7rem] md:w-[1.35rem] w-[1rem] lg:h-[1.7rem] md:h-[1.35rem] h-[1rem] absolute transform -translate-y-1/2 lg:left-[1.5rem] md:left-[1.1rem] left-[0.8rem] top-1/2"
              alt=""
              src="/lens.svg"
            />
            <img
              src="/cross.svg"
              alt="close"
              onClick={handleBlur}
              className="lg:h-[2rem] md:h-[1.6rem] h-[1.2rem] lg:w-[2rem] md:w-[1.6rem] w-[1.2rem] absolute transform -translate-y-1/2 top-1/2 lg:left-[54.9rem] md:right-[1rem] right-[0.8rem] cursor-pointer"
            />
          </div>
          {Boolean(filteredAirports.length !== 0) && (
            <div className="border-solid border-2 border-[#E0DCFF] rounded-2xl absolute lg:top-[6rem] md:top-[4rem] top-[3rem] max-h-[20rem] overflow-y-scroll lg:w-[auto] w-[100%] bg-white z-10">
              {filteredAirports.map((airport: any) => (
                <div
                  onClick={() => handleClick(airport.city)}
                  className="flex flex-row bg-white md:w-[57.25rem] w-[100%] rounded-2xl hover:bg-[#e6e6e6] hover:rounded-none cursor-pointer"
                >
                  <img
                    className="lg:w-[2rem] md:w-[1.55rem] w-[1.1rem] lg:h-[2rem] md:h-[1.55rem] h-[1.1rem] lg:mx-[1.5rem] md:mx-[1.15rem] mx-[0.8rem] lg:mt-[1.5rem] md:mt-[0.95rem] mt-[0.6rem]"
                    alt=""
                    src="/airplane.svg"
                  />
                  <div className="m-0 lg:py-[1.5rem] md:py-[1rem] py-[0.5rem]">
                    <p className="lg:text-[1.5rem] md:text-[1.2rem] text-[0.9rem] m-0 font-dm font-[700] text-[#484660] md:mb-[0.62rem] lg:mb-[0.75rem] mb-[0.5rem]">
                      {`${airport.city} (${airport.code})`}
                    </p>
                    <p className="lg:text-[1.1rem] md:text-[0.9rem] text-[0.7rem] m-0 font-dm font-normal text-[#484660]">
                      {airport.country}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {resultData.length > 0 ? (
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-y-[2rem] gap-x-[1.5rem] mt-[2rem] text-center">
            {resultData.map((item: any) => (
              <div className="relative mx-auto" key={item.id}>
                <div className="relative group mb-[0.7rem] ">
                  <img
                    className="lg:w-[18.5rem] md:w-[18.5rem] 3xs:w-[18.5rem] 4xs:w-[18.5rem] w-[100%] lg:h-[11.25rem] md:h-[11.25rem] h-[11.25rem] rounded-xl"
                    alt=""
                    src={`${Manifest.SERVER_URL}/${item.destinationCity.path}/${item.destinationCity.sourceName}`}
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      target.src = "/fallbackImg.jpg";
                    }}
                  />
                  {/* Hover Links */}
                  <div className="absolute inset-0 md:bottom-[0.2rem] bottom-[0.3rem] z-30 flex flex-col items-center justify-center transition-opacity duration-300 bg-black opacity-0 pointer-events-none rounded-xl bg-opacity-60 group-hover:opacity-100 group-hover:pointer-events-auto">
                    <p className="text-white font-dm text-[0.75rem] font-normal">
                      Book Via
                    </p>
                    <div className="flex space-x-4 text-white">
                      <a
                        href={airIndiaBookingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 py-1 no-underline text-[0.88rem] text-black bg-white font-dm font-semibold rounded hover:bg-slate-100 flex items-center"
                      >
                        <img
                          src="/redirectIcon.svg"
                          className="w-[1rem] h-[1rem] mr-1"
                        />
                        Air India
                      </a>
                      <a
                        href={vistaraBookingUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="px-2 py-1 no-underline text-[0.88rem] bg-white text-black font-dm rounded font-semibold hover:bg-slate-100 flex items-center"
                      >
                        <img
                          src="/redirectIcon.svg"
                          className="w-[1rem] h-[1rem] mr-1"
                        />
                        Vistara
                      </a>
                    </div>
                  </div>
                </div>
                <div className="flex items-center bg-[#FFFFFF] rounded-md px-[0.5rem] py-[0.3rem] absolute lg:top-[8.4rem] md:top-[8.6rem] top-[36%] ml-[0.7rem]">
                  <img
                    className="w-[1.25rem] h-[1.25rem] rounded-full shadow-2xl"
                    src={`${Manifest.SERVER_URL}/${item.destinationFlag.path}/${item.destinationFlag.sourceName}`}
                  />
                  <p className="m-0 font-dm text-[0.875rem] font-medium ml-[0.5rem]">{`${item.to} (${item.toCode})`}</p>
                </div>
                <div className="grid grid-cols-2 grid-rows-2 gap-[0.5rem] lg:w-[18.5rem] md:w-[18.5rem] 3xs:w-[18.5rem] 4xs:w-[18.5rem] w-[100%] md:h-[11rem] h-[auto] font-dm">
                  <ClasswiseInfo
                    vistaraPrice={item.ukEco}
                    airIndiaPrice={item.aiEcoVal}
                    title="Economy"
                  />
                  <ClasswiseInfo
                    vistaraPrice={item.ukEco}
                    airIndiaPrice={item.aiEcoPrime}
                    title="Economy Prime"
                  />
                  <ClasswiseInfo
                    vistaraPrice={item.ukPEco}
                    airIndiaPrice={item.aiPEco}
                    title="Premium Economy"
                  />
                  <ClasswiseInfo
                    vistaraPrice={item.ukBus}
                    airIndiaPrice={item.aiBus}
                    title="Business"
                  />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No results found.</p>
        )}
      </div>
    </div>
  );
}

function ClasswiseInfo({
  title,
  vistaraPrice,
  airIndiaPrice,
}: {
  title: string;
  vistaraPrice: number;
  airIndiaPrice: number;
}) {
  const na = "N/A";
  const vistaraless = vistaraPrice < airIndiaPrice;
  const airIndiaLess = airIndiaPrice < vistaraPrice;
  const bothEqual = vistaraPrice === airIndiaPrice;
  return (
    <div className="bg-[#F8F8F9] rounded-lg shadow p-[0.5rem] pb-[0.75rem]">
      <p className="text-[#484660] font-medium m-0 text-[0.75rem] text-left">
        {title}
      </p>
      <div className="flex items-center my-[0.3rem]">
        <img
          className={`${
            airIndiaLess && airIndiaPrice !== null
              ? "w-[0.75rem] h-[0.75rem]"
              : "w-[1rem] h-[1rem]"
          } cursor-pointer`}
          src="/club-vistara.svg"
          data-tooltip-id="my-tooltip"
          data-tooltip-content={`Club Vistara`}
          data-tooltip-place="top"
        />
        <Tooltip id="my-tooltip" />
        <div className="ml-[0.25rem] flex items-end">
          <p
            className={`m-0 ${
              airIndiaLess && airIndiaPrice !== null
                ? "text-[0.75rem] text-[#8E8D9E] line-through"
                : vistaraPrice === null
                ? "text-[0.75rem] text-[#8E8D9E]"
                : "text-[0.95rem] text-[#1C1939] font-medium"
            } mx-[0.25rem]`}
          >
            {vistaraPrice ? vistaraPrice : na}
          </p>
          <p
            className={`m-0 text-[0.75rem] font-medium mb-[0.1rem] ${
              vistaraless && vistaraPrice !== null
                ? `text-[#66BB6A]`
                : `text-[#484660]`
            }`}
          >
            {airIndiaPrice
              ? vistaraless
                ? `${Math.round(
                    ((airIndiaPrice - vistaraPrice) / airIndiaPrice) * 100
                  )}% off`
                : ""
              : ""}
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <>
          <img
            data-tooltip-id="my-tooltip2"
            data-tooltip-content={`Air India`}
            data-tooltip-place="top"
            className={`${
              vistaraless ? "w-[0.75rem] h-[0.75rem]" : "w-[1rem] h-[1rem]"
            } cursor-pointer`}
            src="/air-india-sm.svg"
          />
          <Tooltip id="my-tooltip2" className="rounded-xl" />
          <div className="ml-[0.25rem] flex items-end">
            <p
              className={`m-0 ${
                vistaraless && vistaraPrice !== null
                  ? "text-[0.75rem] text-[#8E8D9E] line-through"
                  : airIndiaPrice === null
                  ? "text-[0.75rem] text-[#8E8D9E]"
                  : "text-[0.95rem] text-[#1C1939] font-medium"
              } mx-[0.25rem]`}
            >
              {airIndiaPrice ? airIndiaPrice : na}
            </p>
            <p
              className={`m-0 text-[0.75rem] font-medium mb-[0.1rem] ${
                airIndiaLess ? `text-[#66BB6A]` : `text-[#484660]`
              }`}
            >
              {vistaraPrice
                ? airIndiaLess && airIndiaPrice !== null
                  ? `${Math.round(
                      ((vistaraPrice - airIndiaPrice) / vistaraPrice) * 100
                    )}% off`
                  : ""
                : ""}
            </p>
          </div>
        </>
      </div>
    </div>
  );
}
