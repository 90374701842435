import React, { useState, useEffect } from "react";
import Slider, { CustomArrowProps } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./blog-section.css";
import Manifest from "../../constants/Manifest";
import Button from "../Button";
import ErrorBoundary from "../../lib/ErrorBoundary";
import { useDeviceType } from "../../lib/hooks/useDeviceType";
import { UaEventOptions } from "react-ga4/types/ga4";
import ReactGA from "react-ga4";
interface Category {
  id: number;
  name: string;
  slug: string;
}

interface Article {
  id: number;
  title: { rendered: string };
  excerpt: { rendered: string };
  link: string;
  yoast_head_json?: any;
  categories: number[];
  date: string;
}

function CustomFallback() {
  return <div></div>;
}

export default ErrorBoundary(CustomFallback)(BlogSection);

function BlogSection() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number | null>(null);
  const [articles, setArticles] = useState<Article[]>([]);
  const [latestArticles, setLatestArticles] = useState<Article[]>([]);

  useEffect(() => {
    fetchLatestArticles();
    fetchCategories();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      const categoryIds = categories.map((category) => category.id).join(",");
      fetchArticles(categoryIds);
    }
  }, [categories]);

  const fetchCategories = async () => {
    try {
      const response = await fetch(
        `${Manifest.blogUrl}/wp-json/wp/v2/categories?per_page=100&page=1`
      );
      const data: Category[] = await response.json();
      const categories = data.filter(
        (data) =>
          data.name.toLowerCase() === "club vistara" ||
          data.name.toLowerCase() === "axis bank credit cards" ||
          data.name.toLowerCase() === "travel diaries" ||
          data.name.toLowerCase() === "marriott bonvoy" ||
          data.name.toLowerCase() === "krisflyer by singapore airlines"
      );
      setCategories(categories);
      setSelectedCategory(null);
    } catch (error) {}
  };

  const fetchArticles = async (categoryIds: string) => {
    try {
      const response = await fetch(
        `${Manifest.blogUrl}/wp-json/wp/v2/posts?categories=${categoryIds}&per_page=100`
      );
      const data: Article[] = await response.json();
      setArticles(data);
    } catch (error) {}
  };

  const fetchLatestArticles = async () => {
    try {
      const response = await fetch(
        `${Manifest.blogUrl}/wp-json/wp/v2/posts?per_page=9`
      );
      const data: Article[] = await response.json();
      setLatestArticles(data);
    } catch (error) {}
  };

  const filteredArticles =
    selectedCategory === null
      ? latestArticles
      : articles
          .filter((article) => article.categories.includes(selectedCategory))
          .slice(0, 6);

  return (
    <div className="w-full bg-[#FAF9F6] flex justify-center">
      <div className="pc:w-[78rem] md:w-[46.2rem] w-full pc:pb-[13rem] md:pb-[12rem] pb-[5.5rem] relative blog-section overflow-hidden">
        <div className="m-0 md:mt-[6rem] mt-[5rem] pc:mb-[4.5rem] md:mb-[3.5rem] mb-[2rem] text-center">
          <p className="m-0 md:text-[1.125rem] text-[1rem] text-[#52525B] leading-[1.875rem] font-medium font-body-lg-medium pc:mb-[1rem] md:mb-[1.125rem] mb-[1rem] px-[1rem]">
            Exclusive tips and strategies from the rewards experts.
          </p>
          <h1 className="m-0 px-[1rem] text-[#111827] pc:text-[2.625rem] md:text-[2.125rem] text-[1.75rem] font-body-lg-medium pc:font-bold font-extrabold md:leading-[3rem] leading-normal">
            Unlock Your Reward Potential
          </h1>
        </div>
        <div className="md:ml-[1.3rem] md:mr-[1.3rem] mb-[2.5rem] overflow-x-scroll scrollbar-hide ml-[16px]">
          <div className="flex whitespace-nowrap">
            <Button
              isSelected={selectedCategory === null}
              onClick={() => setSelectedCategory(null)}
            >
              All Categories
            </Button>
            {categories.map((category, index) => (
              <Button
                key={category.id}
                isSelected={selectedCategory === category.id}
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.name}
              </Button>
            ))}
          </div>
        </div>
        <BlogsInCategories filteredArticles={filteredArticles} />
      </div>
    </div>
  );
}

interface BlogsInCategoriesProps {
  filteredArticles: Article[];
}

const BlogsInCategories: React.FC<BlogsInCategoriesProps> = ({
  filteredArticles,
}) => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const { isDesktop } = useDeviceType();

  const PrevArrowComponent: React.FC<CustomArrowProps> = ({
    className,
    onClick,
    currentSlide,
  }) => {
    return (
      <div className={className} onClick={onClick}>
        <img
          src={
            currentSlide === 0 ? "/prev-arrow-disabled.svg" : "/prev-arrow.svg"
          }
          alt="Previous"
        />
      </div>
    );
  };

  const NextArrowComponent: React.FC<CustomArrowProps> = ({
    className,
    onClick,
    currentSlide,
    slideCount,
  }) => {
    const ItemsPerSlide = isDesktop ? 3 : 2;
    return (
      <div className={className} onClick={onClick}>
        <img
          src={
            currentSlide === slideCount! - ItemsPerSlide
              ? "/next-arrow-disabled.svg"
              : "/next-arrow.svg"
          }
          alt="Next"
        />
      </div>
    );
  };

  const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <PrevArrowComponent />,
    nextArrow: <NextArrowComponent />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.155,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          centerMode: true,
          centerPadding: "0rem",
          variableWidth: false,
        },
      },
    ],
    customPaging: function (i: number) {
      return (
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            background: currentSlide === i ? "#484660" : "#D9D9D9",
            marginRight: "15px",
          }}
        />
      );
    },
    afterChange: (current: number) => {
      setCurrentSlide(Number.isInteger(current) ? current : Math.ceil(current));
    },
    variableWidth: true,
  };

  return (
    <>
      <Slider key={JSON.stringify(filteredArticles)} {...carouselSettings}>
        {filteredArticles.map(renderArticleCard)}
      </Slider>
      <AllBlogs />
    </>
  );
};

const renderArticleCard = (article: Article, index: number) => {
  const date = new Date(article.date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  function handleClick() {
    const event: UaEventOptions & { [key: string]: any } = {
      category: "blogs_clicked",
      action: "click",
      label: article.title.rendered,
      blog_id: article.id,
    };
    if (process.env.NODE_ENV !== "development") {
      ReactGA.event(event);
    }
  }

  return (
    <div key={`${index}-${article.id}`} className="item">
      <a
        href={article.link}
        onClick={handleClick}
        target="_blank"
        className="m-0 no-underline outline-none"
        rel="noopener noreferrer"
      >
        <img
          src={
            article?.yoast_head_json?.og_image?.[0]?.url || "invalid-url.jpg"
          }
          alt=""
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.src = "/blogs_fallback.svg";
          }}
          className="pc:w-[23.5rem] md:w-[20.5rem] w-[calc(100vw*0.822222222)] md:h-[12.25rem] sm:h-[18rem] xs:h-[16rem] 2xs:h-[15rem] 3xs:h-[13rem] h-[10.65rem] shadow-md rounded-lg"
        />
        <div className="pc:w-[23.5rem] md:w-[20.5rem] w-[calc(100vw*0.822222222)] md:mt-[2rem] mt-[1rem]">
          <p className="text-[1rem] m-0 font-body-lg-medium text-[#52525B]">
            {date}
          </p>
          <h3
            className=" m-0 md:mt-[1.25rem] mt-[1rem] text-[1.32rem] font-body-lg-medium text-[#111827] font-bold line-clamp-1"
            dangerouslySetInnerHTML={{
              __html: article.title.rendered,
            }}
          />
          <div
            className="text-[#6B6B6D] md:m-0 m-0 mt-[-0.3rem] font-body-lg-medium font-normal text-[1rem] line-clamp-2 leading-[1.6rem]"
            dangerouslySetInnerHTML={{
              __html: article.excerpt.rendered,
            }}
          />
        </div>
      </a>
    </div>
  );
};

function AllBlogs() {
  return (
    <a
      href={Manifest.blogUrl}
      target="_blank"
      className="no-underline md:absolute static pc:top-[49rem] md:top-[47rem] pc:left-[62.5rem] left-[36.2rem] m-0 md:mt-0 mt-[5.5rem] md:block flex items-center justify-center"
    >
      <div className=" text-[#111827] m-0 text-[1rem] font-bold font-body-lg-medium border-b-2 border-x-0 border-t-0 border-solid flex  border-[#111827] pb-2">
        <div className="flex pc:block md:hidden">
          Discover All blogs by Magnify
        </div>
        <div className="hidden pc:hidden md:block">Discover All blogs</div>
      </div>
    </a>
  );
}
