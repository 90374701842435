import Slider, { CustomArrowProps } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonials-section.css";
import { useEffect, useState } from "react";
import { useDeviceType } from "../../lib/hooks/useDeviceType";

export default function TestimonialsSection() {
  const { isDesktop } = useDeviceType();
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  const PrevArrow: React.FC<CustomArrowProps> = ({
    className,
    onClick,
    currentSlide,
  }) => {
    return (
      <div className={className} onClick={onClick}>
        <img
          src={
            currentSlide === 0 ? "/prev-arrow-disabled.svg" : "/prev-arrow.svg"
          }
          alt="Previous"
        />
      </div>
    );
  };

  const NextArrow: React.FC<CustomArrowProps> = ({
    className,
    onClick,
    currentSlide,
    slideCount,
  }) => {
    const ItemsPerSlide = isDesktop ? 3 : 1.75;
    return (
      <div className={className} onClick={onClick}>
        <img
          src={
            currentSlide === slideCount! - ItemsPerSlide
              ? "/next-arrow-disabled.svg"
              : "/next-arrow.svg"
          }
          alt="Next"
        />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 1.75,
          centerPadding: "50px",
          prevArrow: <PrevArrow />,
          nextArrow: <NextArrow />,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.14,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
          centerMode: true,
          centerPadding: "0rem",
        },
      },
    ],
    customPaging: function (i: number) {
      return (
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "50%",
            background: currentSlide === i ? "#484660" : "#D9D9D9",
            marginRight: "15px",
          }}
        />
      );
    },
    afterChange: (current: number) => {
      setCurrentSlide(Number.isInteger(current) ? current : Math.ceil(current));
    },
  };
  return (
    <div className="flex flex-col items-center w-full m-0">
      <h1 className="m-0  mt-[5rem] font-body-lg-medium pc:font-bold font-extrabold pc:text-[2.625rem] md:text-[2.125rem] text-[1.75rem] text-[#111827] md:leading-[3rem] leading-normal md:mb-[4.5rem] mb-[2rem]">
        People love Magnify
      </h1>
      <div className="pc:w-[78rem] md:w-[46.2rem] pc:h-[31.7rem] md:h-[31.7rem] pc:mb-[5.75rem] md:mb-[12rem] w-full md:pb-0 pb-[6rem] relative pc:bg-[url('/public/testimonials-shadow.svg')] md:bg-[url('/public/testimonials-shadow2.svg')] bg-none pc:bg-[length:100%_100%] md:bg-[length:80%_100%] bg-no-repeat bg-top t-section">
        <div className="relative flex flex-col justify-center">
          <img
            src="/testimonials-shadow3.svg"
            className="absolute w-full md:hidden h-[60%]"
          />
          <Slider {...settings} key={"testimonials"} className="mt-[2rem] ">
            <Card
              name="Harshit Bohra"
              designation="Assoc. Manager at Accenture"
              message="Magnify Club has transformed how I manage my airmiles. It tracks miles across multiple programs and shows how many I need for flights and rewards, and the live bonus offers feature keeps me updated on the best deals. Magnify saves me time and has helped optimize my travel plans effortlessly!"
              imageSource="/harshit-bohra.jpeg"
            />
            <Card
              name="Ankit Gupta"
              designation="Travel Enthusiast"
              message="Magnify is a must-have for maximizing rewards! The app’s intuitive interface makes tracking points from credit cards, airmiles, and hotel programs effortless. It seamlessly organizes rewards, helping me make the most of every opportunity. Kudos to the team for creating such a user-friendly app!"
              imageSource="/ankit-gupta.JPG"
            />
            <Card
              name="Yash Sharma"
              designation="Self Employed"
              message="Hello, I’m Yash Sharma. I'd like to share my positive experience with Magnify. Their insights into credit cards are outstanding, making them easy to understand and use. I’ve had such a positive experience that I’d highly recommend Magnify to anyone looking to manage their credit cards wisely. Give it a try!"
              imageSource="/yash-sharma.jpg"
            />
          </Slider>
        </div>
      </div>
    </div>
  );
}

type CardProps = {
  name: string;
  designation: string;
  message: string;
  imageSource: string;
};

function Card({
  name,
  designation,
  message,
  imageSource = "/dummyImage.svg",
}: CardProps) {
  return (
    <div
      className={`m-0 p-[calc(100vw*0.0888888889)] md:p-[2rem] md:mx-[1rem] mx-0 my-[1rem] md:w-[20rem] w-[calc(100vw*0.648888889)] md:h-[23rem] sm:h-[20rem] xs:h-[21rem] 2xs:h-[24rem] 3xs:h-[27rem] h-[30rem] rounded-xl item2 shadow-md bg-white`}
    >
      <div className="flex flex-col justify-between w-full h-full">
        <div>
          <Rating count={5} />
          <h1 className="m-0 mt-[1.5rem] leading-[1.75rem] font-body-lg-medium text-[#1811B] text-[1.125rem] font-normal">
            "{message}"
          </h1>
        </div>

        <div className="flex flex-row items-center">
          <img
            src={imageSource}
            className="w-[43px] h-[43px] rounded-full"
            alt=""
          />

          <div className="ml-[1rem]">
            <h2 className="m-0 font-body-lg-medium font-bold text-[1rem] leading-[1.75rem] text-[#111827]">
              {name}
            </h2>
            <p className="m-0 font-body-lg-medium font-normal text-[0.875rem] text-[#52525B] leading-[1.4rem] ">
              {designation}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

type RatingProp = {
  count: number;
};

function Rating({ count }: RatingProp) {
  return (
    <div className="flex flex-row">
      {[...Array(count)].map((_, index) => (
        <img
          key={index}
          src="/rating-star.svg"
          className="mr-[4px] w-[1rem] h-[1rem]"
          alt=""
        />
      ))}
    </div>
  );
}
