import { FunctionComponent, Suspense, useEffect, useState } from "react";
import HomeContainer from "../../components/home-container";
import Header from "../../components/header";
import BottomFooter from "../../components/bottom-footer";
import RewardTracked from "../../components/RewardsTracked/rewards-tracked";
import LogoCarousel from "../../components/LogoCarousel/logo-carousel";
import BlogSection from "../../components/BlogSection/blog-section";
import PopularFeatures from "../../components/PopularFeatures/popular-features";
import TestimonialsSection from "../../components/TestimonialsSection/testimonials-section";
import { useDeviceType } from "../../lib/hooks/useDeviceType";

const LandingPage: FunctionComponent = () => {
  const { isMobile } = useDeviceType();

  return (
    <div className="relative flex flex-col items-center w-full bg-white">
      <img
        src={isMobile ? "/bg_mobile.svg" : "/bg_pc.svg"}
        className="absolute w-full pc:h-screen md:h-[85vh] h-[80rem]"
        alt=""
      />
      <Header />
      <HomeContainer />
      <RewardTracked />
      <PopularFeatures />
      <BlogSection />
      <TestimonialsSection />
      <LogoCarousel />
      <BottomFooter />
    </div>
  );
};

export default LandingPage;
